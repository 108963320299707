import React from "react";

import About from "../components/About/About";
import Carousel from "../components/slider/Carousel";
import Services from "../components/Services/Services";
import Clients from "../components/Clients/Clients";
import ContactForm from "../components/Contact/ContactForm";

function HomePage({ sectionRefs }) {
  const { aboutRef, servicesRef, clientRef, contactRef } = sectionRefs;
  return (
    <div>
      <Carousel />

      <div ref={servicesRef} id="services">
        <Services />
      </div>
      <div ref={aboutRef} id="about">
        <About />
      </div>
      <div ref={clientRef} id="client">
        <Clients />
      </div>
      <div ref={contactRef} id="contact">
        <ContactForm />
      </div>
    </div>
  );
}

export default HomePage;
