import React from "react";
import About from "../components/About/About";
import Carousel from "../components/slider/Carousel";

function AboutUsPage() {
  return (
    <div>
      <Carousel />
      <About />
    </div>
  );
}

export default AboutUsPage;
