import { React, useRef } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUsPage from "./Pages/AboutUsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import ServicesPage from "./Pages/ServicesPage";
import ClientsPage from "./Pages/ClientsPage";
import HomePage from "./Pages/HomePage";

import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";

function Layout() {
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const clientRef = useRef(null);
  const contactRef = useRef(null);
  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const sectionRefs = {
    aboutRef,
    servicesRef,
    clientRef,
    contactRef,
  };
  return (
    <div>
      <BrowserRouter>
        <Navbar scrollToSection={scrollToSection} sectionRefs={sectionRefs} />

        <main>
          <Routes>
            <Route path="/" element={<HomePage sectionRefs={sectionRefs} />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/clients" element={<ClientsPage />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Layout;
