import React from "react";
import ContactForm from "../components/Contact/ContactForm";
import Carousel from "../components/slider/Carousel";

function ContactUsPage() {
  return (
    <div>
      <Carousel />
      <ContactForm />
    </div>
  );
}

export default ContactUsPage;
