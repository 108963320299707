import useInput from "../hooks/use-input";

import CardBackground from "../ui/CardBackground";
import Button from "../ui/Button";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");

const ContactForm = () => {
  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput(isNotEmpty);
  const {
    value: emailValue,
    setvalue: setEmailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);
  const {
    value: mobileValue,
    setvalue: setMobileValue,
    isValid: mobileIsValid,
    hasError: mobileHasError,
    valueChangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    reset: resetMobile,
  } = useInput(isNotEmpty);
  const {
    value: msgValue,
    isValid: msgIsValid,
    hasError: msgHasError,
    valueChangeHandler: msgChangeHandler,
    inputBlurHandler: msgBlurHandler,
    reset: resetMsg,
  } = useInput(isNotEmpty);
  let formIsValid = false;

  if (nameIsValid && emailIsValid && mobileIsValid && msgIsValid) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    // Create the mailto link with form data
    const mailtoLink = `mailto:svpnunna@gmail.com?subject=Message from ${encodeURIComponent(
      nameValue
    )}&body=Name: ${encodeURIComponent(
      nameValue
    )}%0AEmail: ${encodeURIComponent(
      emailValue
    )}%0AMobileNumber: ${encodeURIComponent(
      mobileValue
    )}%0A%0A%0AMessage: ${encodeURIComponent(msgValue)}`;

    // Open the mailto link in a new window/tab
    window.location.href = mailtoLink;

    // console.log("Added");
    resetName();
    resetEmail();
    resetMsg();
    resetMobile();
  };

  const nameClasses = nameHasError ? "form-control invalid" : "form-control";
  const emailClasses = emailHasError ? "form-control invalid" : "form-control";
  const mobileClasses = mobileHasError
    ? "form-control invalid"
    : "form-control";
  const msgClasses = msgHasError ? "form-control invalid" : "form-control";
  return (
    <div className="Block">
      <CardBackground>
        <h3> Drop An Email </h3>

        <form onSubmit={submitHandler}>
          <div className="control-group">
            <div className={nameClasses}>
              <input
                type="text"
                placeholder="Your Name"
                value={nameValue}
                onChange={nameChangeHandler}
                onBlur={nameBlurHandler}
              />
              {nameHasError && (
                <p className="error-text">Please enter your name.</p>
              )}
            </div>
            <div className={emailClasses}>
              <input
                type="text"
                placeholder="Email."
                value={emailValue}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
              />
              {emailHasError && (
                <p className="error-text">Please enter a Email.</p>
              )}
            </div>
            <div className={mobileClasses}>
              <input
                type="number"
                placeholder="Mobile Number."
                value={mobileValue}
                onChange={mobileChangeHandler}
                onBlur={mobileBlurHandler}
              />
              {mobileHasError && (
                <p className="error-text">Please enter a Mobile Number.</p>
              )}
            </div>
            <div className={msgClasses}>
              <textarea
                type="text"
                placeholder="Your Message."
                value={msgValue}
                onChange={msgChangeHandler}
                onBlur={msgBlurHandler}
              />
              {msgHasError && (
                <p className="error-text">Please enter a Message.</p>
              )}
            </div>
          </div>

          <div style={{ width: "40%", padding: "auto", margin: "auto" }}>
            <Button type="submit" disabled={!formIsValid}>
              Submit
            </Button>
          </div>
        </form>
      </CardBackground>
    </div>
  );
};

export default ContactForm;
