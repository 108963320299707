import { React, useState } from "react";
import styles from "./Navbar.module.css";
import Logo from "../../images/logo.png";
import { MdEmail, MdPhone } from "react-icons/md";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = ({ scrollToSection, sectionRefs }) => {
  const [nav, setNav] = useState(false);
  const mailtoLink = `mailto:contactus@Dattamsa.org?subject=Hello&body=This%20is%20a%20test%20email`;

  return (
    <header className={styles.navbar}>
      <div className={styles.logoImage}>
        <img src={Logo} alt="Logo" />
      </div>
      <nav>
        <ul
          className={
            nav ? [styles.menu, styles.active].join(" ") : [styles.menu]
          }
        >
          <li>
            <a href="/">Home</a>
          </li>
          <li onClick={() => scrollToSection(sectionRefs.aboutRef)}>
            <a href="/#">About</a>
          </li>
          <li onClick={() => scrollToSection(sectionRefs.servicesRef)}>
            <a href="/#">Services</a>
          </li>
          <li onClick={() => scrollToSection(sectionRefs.clientRef)}>
            <a href="/#">Our Clients</a>
          </li>
          <li onClick={() => scrollToSection(sectionRefs.contactRef)}>
            <a href="/#">Contact</a>
          </li>
          <li className={styles.last}>
            <a href={mailtoLink}>
              <MdEmail size={25} color="#2D1044" />
              <p>contactus@Dattamsa.org</p>
            </a>
          </li>

          <li className={styles.last}>
            <MdPhone size={25} color="#2D1044" /> <p>+91-9052247773</p>
          </li>
        </ul>
      </nav>
      <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
        {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
      </div>
    </header>
  );
};

export default Navbar;
