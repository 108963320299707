import React from "react";
import styles from "./Services.module.css";
import service1 from "../../images/services/service1.png";
import service2 from "../../images/services/service2.png";
import service3 from "../../images/services/service3.png";
import service4 from "../../images/services/service4.png";

const Services = () => {
  return (
    <div className={styles.luxury}>
      <div className={styles.container}>
        <div className={styles.card}>
          <img src={service1} alt="example" />

          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <p>
                <b>Placement and Career </b>
              </p>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src={service2} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <p>
                <b>Training and Development </b>
              </p>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src={service3} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <p>
                <b>Data Management</b>
              </p>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src={service4} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <p>
                <b>CTC Designing and Payroll processing </b>
              </p>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
