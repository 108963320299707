import React from "react";
import Clients from "../components/Clients/Clients";
import Carousel from "../components/slider/Carousel";

function ClientsPage() {
  return (
    <div>
      <Carousel />
      <Clients />
    </div>
  );
}

export default ClientsPage;
